<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetchingCmsSite">
      <b-tabs content-class="mt-3">
        <b-tab title="General" active><CmsGeneralSetting /></b-tab>
        <b-tab v-if="adminRole === 'OWNER'" title="Advance Configugation"
          ><CmsConfigurationSetting
        /></b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    CmsGeneralSetting: () =>
      import('../../../components/cms/general-setting.vue'),
    CmsConfigurationSetting: () =>
      import('../../../components/cms/advance-configuration-setting.vue'),
  },
  computed: {
    ...mapGetters(['adminRole']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
}
</script>
